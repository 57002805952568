
$(window).on('load', function () {
    let timerId;
    const debounce = (fn, delay) => {
        clearTimeout(timerId);
        timerId  =  setTimeout(fn, delay);
    }
    window.addMasks(document, '.js-input-tel-');
    $('body').on('click', '.default_address', function () {
        check_default_address(this);
    });
    $('body').on('submit', '.order_stop_form', function () {
        return check_order_stop_form(this);
    });
    $('body').on('input', '.prolong_my_days', function () {
        return check_prolong(this);
    });
    $('body').on('submit', '.profile_prolong_form', function () {
        return send_prolong(this);
    });
    $('body').on('submit', '.profile_prolong_as_sub_form', function () {
        return send_prolong_as_sub(this);
    });
    $('body').on('click', '.prolong_popup_link', function () {
        return send_prolong_as_sub($(this).parents('.profile_prolong_as_sub_form'));
    });
    $('body').on('click', '.prolong_sub_agreed', function () {

        if ($(this).prop('checked') == true) {
            $(this).parent().find('span').removeClass('is-error');
        } else {
            $(this).parent().find('span').addClass('is-error');
        }
    });

    $('body').on('click', '.confirm_sub_prolong', function () {
        var service_id = $(this).find('.service_id').val();
        $('.profile_prolong_as_sub_form_' + service_id).submit();
    });

    $('body').on('input', '.login_username', function (e) {
        debounce(() => login_by_phone(this), 300);
    });
    $('body').on('click', '.login_phone_button', function () {
        send_phone_login_code(this);
    });
    $('body').on('click', '.check_login_phone_button', function () {
        check_phone_authorization_code(this);
    });
    $('body').on('focus', '.check_login_phone_field', function () {
        window.ready_field(this);
    });
    $('body').on('click', '.profile_order_delete', function () {
        return profile_order_delete(this);
    });
    $('body').on('click', '.estimate_link', function () {
        estimate_order(this);
    });
    $('body').on('click', '.profile-order-checkbox.partial_return', function () {
        check_partial_return_checkbox();
    });


    $('body').on('click', '.js-order-items-select', function () {
        var data_order = $(this).attr('data-order');
		//console.log(data_order);
        $('.checkbox-' + data_order).each(function () {
            $(this).addClass('partial_return');
            $(this).parents('.order-table__i-box').addClass('has-checkbox');
        });
        $('.full-return-' + data_order).addClass('nod');
        //$('.partial-return-'+data_order).removeClass('nod');
        check_partial_return_checkbox();
        return false;
    });


    $('body').on('input', '.check_login_phone_field', function () {
        var val = $(this).val();

        //console.log(val);
        if (val.length == 6) {
            $(this).parents('.inputbox').find('.check_login_phone_button').click();
        }

    });

    $('body').on('click', '.profile_prolong_form .days_list', function () {
        var val = $(this).find('.field').val();
        $(this).parents('.profile_prolong_form').find('.day_count').val(val);
    });

    login_by_phone(null, false);
    if ($('.profile-order-checkbox').length > 0) {
        $('.order-table__checkbox').removeClass('nod');
        //$('.user-orders__cancel').removeClass('nod');
    }
    $('.messenger').each(function () {
        check_support_messages(this);
    });

    $('body').on('click', '.js-subs-cont-toggle', function (e) {
        e.preventDefault();
        $(this).toggleClass('is-open').closest('.order-table__i-box').find('.order-table__subs-dd').slideToggle();
    });

    $('body').on('change', '.profile_bill_date', function () {
        if ($(this).hasClass('date_start')) {
            setCookie('profile_bill_date_start', $(this).val());
        }
        if ($(this).hasClass('date_end')) {
            setCookie('profile_bill_date_end', $(this).val());
        }
        window.location.reload();
    });

    $('body').on('change', '.profile_card_history_date', function () {
        if ($(this).hasClass('date_start')) {
            setCookie('profile_card_history_date_start', $(this).val());
        }
        if ($(this).hasClass('date_end')) {
            setCookie('profile_card_history_date_end', $(this).val());
        }
        window.location.reload();
    });
});

const firstRecovery = (e) => {
    e.preventDefault();
    activate_phone(e.target);
    return false;
};
const secondRecovery = (e) => {
    e.preventDefault();
    activate_phone_code(e.target);
    return false;
};
const finishRecovery = (e) => {
    e.preventDefault();
    change_pass(e.target);
    return false;
};
const recoveryButton = document.querySelector('.recovery_button');

document.addEventListener('DOMContentLoaded', () => {
    if (recoveryButton) {
        recoveryButton.addEventListener('click', firstRecovery);
    }
})

function check_partial_return_checkbox() {
    $('.profile-order-checkbox.partial_return').each(function () {
        var wrapper = $(this).parents('.profile_order_wrapper');
        if (wrapper) {

            var order_number = $(wrapper).find('.order_number').val();
            if (order_number) {

                $('.partial-return-order-' + order_number).addClass('nod');
                var action_service_link = $('a.partial-return-order-' + order_number);
                var action_service_list = $(action_service_link).find('input[name="service_list"]');

                if (action_service_list) {
                    var service_list = [];
                    var i = 0;
                    $(wrapper).find('.checkbox-order-' + order_number).each(function () {
                        if ($(this).prop('checked')) {
                            service_list[i] = $(this).val();
                            i++;
                        }
                    });

                    if (service_list.length == 0) {
                        $(action_service_list).val('');
                    }
                    if (service_list.length > 0) {
                        $(action_service_list).val(service_list.join(','));
                        $('.partial-return-order-' + order_number).removeClass('nod');
                    }
                }
            }
        }
    });
}

function estimate_order(elem) {
    if (elem) {
        var wrapper = $(elem).parents('.profile_estimate_form');
        var url = $(wrapper).attr('action');
        var data = new Object();
        data.number = $(elem).find('.number').val();

        var ajax_obj = {
            url: url,
            type: 'post',
            beforeSend: function () {
                $(wrapper).find('.loader_wrapper').removeClass('nod');
                $(wrapper).find('.field_wrapper').addClass('nod');
            },
            data: data,
            dataType: 'json',
            complete: function (answer) {
                $(wrapper).find('.loader_wrapper').addClass('nod');

                if (answer.responseText) {
                    try {
                        res = $.parseJSON(answer.responseText);
                    } catch (err) {
                        res = new Object();
                    }
                    if (res.message) {
                        $(wrapper).find('.result_wrapper').html(res.message);
                    }
                    if (res.order_id) {
                        $('.estimate_button_' + res.order_id).remove();
                    }
                    if (res.result == 0) {
                        $(wrapper).find('.result_wrapper').addClass('error_color');
                    }
                    if (res.result == 1) {
                        $(wrapper).find('.result_wrapper').addClass('good_color');

                    }
                }
            },
            cache: false,
            async: true,
        };
        $.ajax(ajax_obj);
    }
    return false;
}

function profile_order_delete(elem) {

    if (elem) {

        var url = $(elem).attr('href');

        var ajax_obj = {
            url: url,
            type: 'post',
            beforeSend: function () {
                $(elem).find('.loader_wrapper').removeClass('nod');
                $(elem).find('.field_wrapper').addClass('nod');
            },
            dataType: 'json',
            complete: function (data) {
                $(elem).find('.loader_wrapper').addClass('nod');

                if (data.responseText) {
                    try {
                        res = $.parseJSON(data.responseText);
                    } catch (err) {
                        res = new Object();
                    }

                    $(elem).parent().find('.js-popup-close').click();

                    if (res.order_id) {
                        $('.order_wrapper_' + res.order_id).remove();
                    }
                    return false;
                }
            },
            cache: false,
            async: true,
        };
        $.ajax(ajax_obj);
    }
    return false;
}

function check_support_messages(elem, no_repeat) {
    if (elem) {
        var data = new Object();
        data.from = 'profile';
        if ($('.message_wrapper').length > 0) {
            var max_message_date = moment($('.message_wrapper').find('.message_time').val());
            if (max_message_date) {
                var min_message_date = max_message_date;

                $('.message_wrapper').each(function () {
                    current_message_date = moment($(this).find('.message_time').val());
                    min_message_date = moment.min(min_message_date, current_message_date);
                    max_message_date = moment.max(max_message_date, current_message_date);
                });
                data.date_from = max_message_date.format('YYYY-MM-DD HH:mm:ss');
            }
        }
        var bottom_max = $(elem).parents('.message_wrapper').offset().top + $(elem).parents('.message_wrapper').height();
        var bottom_line = $(elem).find('.bottom').offset().top;
        if (bottom_line < bottom_max) {
            data.date_before = min_message_date.format('YYYY-MM-DD HH:mm:ss');
        }

        const locationPath = document.location.pathname;
        const currentLanding = locationPath.split('/').slice(0, 3).join('/');
        var ajax_obj = {
            url: `${currentLanding}/profile/support/messages/`,
            type: 'post',
            beforeSend: function () {
                $(elem).find('.loader_wrapper').removeClass('nod');
            },
            data: data,
            dataType: 'json',
            complete: function (data) {
                $(elem).find('.loader_wrapper').addClass('nod');
                if (data.responseText) {
                    try {
                        res = $.parseJSON(data.responseText);
                    } catch (err) {
                        res = new Object();
                    }

                    if (res.message_list) {

                        for (i = 0; i < res.message_list.length; i++) {
                            var message = res.message_list[i];
                            var is_message = $(elem).find('.message_' + message.id);

                            if (is_message.length == 0) {
                                var is_date_wrapper = $(elem).find('.date_wrapper.date_' + message.date);
                                if (is_date_wrapper.length == 0) {
                                    var new_wrapper = $('<div/>');
                                    new_wrapper.addClass('date_wrapper').addClass('date_' + message.date);

                                    $($(elem).find('.date_wrapper').get().reverse()).each(function () {
                                        var list_date = moment(message.date);
                                        if (list_date.isAfter($(this).find('.date_day').val())) {
                                            if ($(elem).find('.date_wrapper.before').length == 0) {
                                                $(this).addClass('before');
                                                $(elem).find('.date_wrapper.before').after(new_wrapper);
                                            }
                                        }
                                    });

                                    if ($(elem).find('.date_wrapper.before').length == 0) {
                                        $(elem).prepend(new_wrapper);
                                    }

                                    is_date_wrapper = $(elem).find('.date_wrapper.date_' + message.date);
                                    is_date_wrapper.prepend('<div class="messenger__datetime">' + message.date_name + '</div>');
                                    is_date_wrapper.prepend('<input type="hidden" class="date_day" value="' + message.date + '">');

                                }

                                $(is_date_wrapper).find('.message_wrapper').each(function () {
                                    //var list_time = moment($(this).find('.message_time').val());
                                    var message_id = parseInt($(this).find('.message_id').val());

                                    if (parseInt($(message.html).find('.message_id').val()) < message_id)
                                        //if (list_time.isAfter($(message.html).find('.message_time').val()))
                                    {
                                        if ($(is_date_wrapper).find('.message_wrapper.before').length == 0) {
                                            $(this).addClass('before');
                                            $(is_date_wrapper).find('.message_wrapper.before').before(message.html);
                                        }
                                    }
                                });
                                if ($(is_date_wrapper).find('.message_wrapper.before').length == 0) {
                                    $(is_date_wrapper).append(message.html);

                                }

                                $(elem).find('.message_wrapper').removeClass('before');
                                go_chat_bottom(elem);
                            }
                        }

                        $(elem).find('.date_wrapper').removeClass('before');

                    }
                }
            },
            cache: false,
            async: false,
        };

        $.ajax(ajax_obj);


        $(elem).removeClass('first_time');
        if (no_repeat != 1) {
            setTimeout(function () {
                check_support_messages(elem);
            }, 3000);
        }
    }
}

function go_chat_bottom(elem) {
    if (elem) {
        var jsp = $(elem).parents('.message_wrapper').data('jsp');
        message_list = $(elem).find('.message_wrapper');
        var last_id = message_list.last().attr('id');
        jsp.reinitialise();
        jsp.scrollToElement($('#' + last_id), false, true);
    }
}

function check_phone_authorization_code(elem) {
    if (elem) {
        var wrapper = $(elem).parents('.inputbox');
        var is_ok = check_field($(wrapper).find('.check_login_phone_field'), 1);

        if (is_ok) {
            var input = $(wrapper).find('.check_login_phone_field');
            var data = { code: $(input).val(), username: $(wrapper).find('.login_username').val() };

            var ajax_obj = {
                url: '/login/code/',
                type: 'post',
                beforeSend: function () {
                    $(elem).addClass('nod');
                    $(wrapper).find('.alert-error').addClass('nod').val('');
                    $(wrapper).find('.is-wait').removeClass('nod');
                    $(wrapper).find('.is-process').addClass('nod');
                },
                data: data,
                dataType: 'json',
                complete: function (data) {
                    if (data.responseText) {
                        //console.log(data.responseText);
                        res = eval('(' + data.responseText + ')');
                        $(wrapper).find('.is-wait').addClass('nod');
                        if (res.result == 1) {
                            $(wrapper).find('.alert-error').addClass('nod').val('');
                            $(wrapper).find('.phone_counter').html(0);
                            $(wrapper).find('.phone_counter').parent().addClass('nod');
                            $(wrapper).find('.is-begin').addClass('nod');
                            $(wrapper).find('.is-process').addClass('nod');
                            if (res.message) {
                                $(wrapper).find('.alert-success').html(res.message);
                            }
                            //if (res.mail) {$(contact_wrapper).find('.mail_field').val(res.mail);}
                            if ($(input).hasClass('need_auth')) {
                                const locationPath = document.location.pathname;
                                const currentLanding = locationPath.split('/').slice(0, 3).join('/');
                                window.location.href = `${currentLanding}/profile/orders/`;
                            }
                            //if ($(input).hasClass('need_auth')) {$('#cart_list_form').submit();}

                            //save_cart_user_info();
                        } else if (res.result == 0) {
                            $(wrapper).find('.is-process').removeClass('nod');
                            if (res.error) {
                                $(wrapper).find('.alert-error').html(res.error).removeClass('nod');
                            }
                            //if ($(input).hasClass('need_auth')) {window.location.reload();}
                        }
                    }
                },
                cache: false,
                async: true,
            };

            $.ajax(ajax_obj);
        }
    }
}

function send_phone_login_code(elem) {
    if (elem) {
        var container = $('#profile_login_form');
        if (elem) {
            container = $(elem).parents('form');
        }
        var wrapper = $(elem).parents('.inputbox');
        var data = { username: $(wrapper).find('.login_username').val(), initiator: 'profile_phone_authorization' };

        var ajax_obj = {
            url: '/login/sms/',
            type: 'post',
            beforeSend: function () {
                $(elem).addClass('nod');
                $(wrapper).find('.is-wait').removeClass('nod');
                $(wrapper).find('.inputbox__error').html('');
                $(wrapper).find('.is-begin').addClass('nod');
                $(wrapper).find('.inputbox__input').removeClass('is-error');
            },
            data: data,
            dataType: 'json',
            complete: function (data) {
                if (data.responseText) {

                    res = eval('(' + data.responseText + ')');
                    if (res.result == 1) {
                        $(wrapper).find('.is-wait').addClass('nod');
                        $(wrapper).find('.is-begin').addClass('nod');
                        $(wrapper).find('.is-process').removeClass('nod');
                        if (res.message) {
                            $(wrapper).find('.alert-success').html(res.message);
                        }
                        if (res.error) {
                            $(wrapper).find('.alert-error').html(res.error);
                        }
                    }

                    if (res.result == 0) {
                        $(wrapper).find('.is-wait').addClass('nod');
                        $(wrapper).find('.is-process').addClass('nod');
                        $(wrapper).find('.is-begin').removeClass('nod');
                        if (res.error) {
                            $(wrapper).find('.alert-error').html(res.error);
                        }
                    }
                    if (res.is_counter) {
                        $('.login_phone_button').addClass('nod');
                        var counter = $('.phone_counter');
                        if (parseInt($(counter).html()) > 0) {
                            set_counter(counter);
                        }
                    }
                }
            },
            cache: false,
            async: true,
        };

        $.ajax(ajax_obj);
    }
}

const set_recovery_counter = (elem) => {
    if (!elem) {
        return;
    }

    const wrapper = $(elem).parents('form');
    const new_number = parseInt($(elem).html()) - 1;
    if (new_number < 0) {
        $(elem).parent().html('');
        $(wrapper).find('.recovery_button').removeClass('is_disabled').attr('disabled', false);
    } else {
        $(elem).html(new_number);
        setTimeout(set_recovery_counter, 1000, elem);
        $(wrapper).find('.recovery_button').addClass('is_disabled').attr('disabled', true);
    }
}

const createLoader = () => {
    const loader = document.createElement('div');
    const styles = { width: '100%', position: 'absolute', height: '100%', top: 0, left: 0, background: 'rgba(0,0,0,0.1)' };
    Object.entries(styles).forEach(([name, value]) => loader.style[name] = value);
    loader.innerHTML = `<div class="login_loader cent" style="top: 50%; position: absolute; left: 50%; transform: translate3d(-50%, -50%, 0);">
        <img src="/assets/images/loader.gif" class="loader">
    </div>`;
    return loader;
}

const activate_phone = (elem) => {
    if (!elem) {
        return;
    }
    const container = $(elem).parents('form');
    var wrapper = $(container).find('[name="phone"]').parents('.inputbox');
    var data = { phone: $(container).find('[name="phone"]').val() };
    const loader = createLoader();

    document.querySelector('.password').style.position = 'relative';

    var ajax_obj = {
        url: '/login/recovery/find/',
        type: 'post',
        beforeSend: function () {
            $(elem).attr('disabled', true);
            $(wrapper).find('.is-wait').removeClass('nod');
            $(wrapper).find('.inputbox__error').html('');
            $(wrapper).find('.is-begin').addClass('nod');
            $(wrapper).find('.inputbox__input').removeClass('is-error');
            $(wrapper).find('.alert-error').html('');
            $('.password').append(loader);
        },
        data: data,
        dataType: 'json',
        complete: function (data) {
            $(elem).attr('disabled', false);
            loader.remove();
            if (data.responseText) {
                const res = JSON.parse(data.responseText);
                if (res.result == 1) {
                    $('.password__desc').html('Введите код, высланный на телефон');
                    container.find('label').html('Код из СМС<strong>*</strong>');
                    const inp = document.createElement('input');
                    inp.type = 'password';
                    inp.name = 'code';
                    inp.classList.add('inputbox__input');

                    const passToggle = document.createElement('div');
                    passToggle.classList.add('inputbox__icon','js-pass-toggle');
                    passToggle.style.left = 'auto';
                    passToggle.style.right = '0';
                    passToggle.innerHTML = `<svg class="icon icon-eye-open">
                          <use xlink:href="/assets/images/sprite.svg#eye-open"></use>
                        </svg>
                        <svg class="icon icon-eye-close">
                          <use xlink:href="/assets/images/sprite.svg#eye-close"></use>
                        </svg>`;

                    wrapper.find('.inputbox__field').find('input').addClass('nod').attr('readonly', true);

                    wrapper.find('.inputbox__field').append(inp);
                    wrapper.find('.inputbox__field').append(passToggle);

                    container.find('.recovery_button').find('span').text('Подтвердить');
                    recoveryButton.removeEventListener('click', firstRecovery);
                    recoveryButton.addEventListener('click', secondRecovery);
                }

                if (res.result == 0) {
                    $(wrapper).find('.inputbox__input').addClass('is-error');
                    if (res.error) {
                        $(wrapper).find('.alert-error').html(res.error);
                    }
                }

                if (res.is_counter) {
                    $(elem).attr('disabled', true);
                    var counter = $('.phone_counter');
                    if (parseInt($(counter).html()) > 0) {
                        set_recovery_counter(counter);
                    }
                }
            }
        },
        cache: false,
        async: true,
    };

    $.ajax(ajax_obj);
};

const activate_phone_code = (elem) => {
    if (!elem) {
        return;
    }
    const container = $(elem).parents('form');
    var wrapper = $(container).find('[name="code"]').parents('.inputbox');
    var data = { code: $(container).find('[name="code"]').val(), username: $(container).find('[name="phone"]').val() };
    const loader = createLoader();

    var ajax_obj = {
        url: '/login/recovery/code/',
        type: 'post',
        beforeSend: function () {
            $(elem).attr('disabled', true);
            $(wrapper).find('.is-wait').removeClass('nod');
            $(wrapper).find('.inputbox__error').html('');
            $(wrapper).find('.is-begin').addClass('nod');
            $(wrapper).find('.inputbox__input').removeClass('is-error');
            $(wrapper).find('.alert-error').html('');
            $('.password').append(loader);
        },
        data: data,
        dataType: 'json',
        complete: function (data) {
            $(elem).attr('disabled', false);
            loader.remove();
            if (data.responseText) {
                const res = JSON.parse(data.responseText);
                if (res.result == 1) {
                    $('.page__title span').text('Укажите ваш новый пароль');
                    $('.password__desc').html('Минимум 6 символов, одна цифра и одна буква обязательны');
                    $(container).find('.inputbox').addClass('nod');
                    const newInput = document.createElement('div');
                    newInput.classList.add('inputbox','inputbox--pass');
                    newInput.innerHTML = `<label>пароль<strong>*</strong></label>
                    <div class="inputbox__field">
                      <input class="inputbox__input" name="password" type="password">
                      <div class="inputbox__icon js-pass-toggle" style="left:auto;right:0">
                        <svg class="icon icon-eye-open">
                          <use xlink:href="/assets/images/sprite.svg#eye-open"></use>
                        </svg>
                        <svg class="icon icon-eye-close">
                          <use xlink:href="/assets/images/sprite.svg#eye-close"></use>
                        </svg>
                      </div>
                    </div>`;
                    const newInputRepeat = document.createElement('div');
                    newInputRepeat.classList.add('inputbox','inputbox--pass');
                    newInputRepeat.innerHTML = `<label>подтверждение пароля<strong>*</strong></label>
                    <div class="inputbox__field">
                      <input class="inputbox__input" name="password_repeat" type="password">
                      <div class="inputbox__icon js-pass-toggle" style="left:auto;right:0">
                        <svg class="icon icon-eye-open">
                          <use xlink:href="/assets/images/sprite.svg#eye-open"></use>
                        </svg>
                        <svg class="icon icon-eye-close">
                          <use xlink:href="/assets/images/sprite.svg#eye-close"></use>
                        </svg>
                      </div>
                    </div>`;

                    container.append(newInput);
                    container.append(newInputRepeat);

                    const errorNew = document.createElement('div');
                    errorNew.classList.add('inputbox__error', 'alert-error');
                    newInputRepeat.append(errorNew);

                    const btn = container.find('.recovery_button');
                    btn.find('span').text('Сменить пароль');
                    const newButton = btn.clone(true);
                    container.append(newButton);
                    recoveryButton.removeEventListener('click', secondRecovery);
                    recoveryButton.classList.add('nod');
                    newButton[0].addEventListener('click', finishRecovery);
                }

                if (res.result == 0) {
                    $(wrapper).find('.inputbox__input').addClass('is-error');
                    if (res.error) {
                        $(wrapper).find('.alert-error').html(res.error);
                    }
                }
            }
        },
        cache: false,
        async: true,
    };

    $.ajax(ajax_obj);
};

const change_pass = (elem) => {
    if (!elem) {
        return;
    }
    const container = $(elem).parents('form');
    var wrapper = $(container).find('[name="code"]').parents('.inputbox');
    var data = {
        code: $(container).find('[name="code"]').val(),
        username: $(container).find('[name="phone"]').val(),
        password: $(container).find('[name="password"]').val(),
        password_repeat: $(container).find('[name="password_repeat"]').val(),
    };
    const loader = createLoader();

    var ajax_obj = {
        url: '/login/recovery/change/',
        type: 'post',
        beforeSend: function () {
            $(elem).attr('disabled', true);
            $(wrapper).find('.is-wait').removeClass('nod');
            $(wrapper).find('.inputbox__error').html('');
            $(wrapper).find('.is-begin').addClass('nod');
            $(wrapper).find('.inputbox__input').removeClass('is-error');
            $(wrapper).find('.alert-error').html('');
            $('.password').append(loader);
        },
        data: data,
        dataType: 'json',
        complete: function (data) {
            $(elem).attr('disabled', false);
            loader.remove();
            if (data.responseText) {
                const res = JSON.parse(data.responseText);
                if (res.result == 1) {
                    $('.page__title span').text('Пароль изменен');
                    $('.password__desc').html(`Мы отправили уведомление на вашу почту.
                        <br>Теперь вы можете
                        <a href="/login/">войти в личный кабинет</a>
                        с новым паролем.`
                    );
                    $(container).find('.inputbox').addClass('nod');
                    $('.recovery_button').remove();
                }

                if (res.result == 0) {
                    $(container).find('.inputbox__input').addClass('is-error');
                    if (res.error) {
                        $(container).find('.alert-error').html(res.error);
                    }
                }
            }
        },
        cache: false,
        async: true,
    };

    $.ajax(ajax_obj);
};


const cache = {};
function login_by_phone(elem = null, do_init = true) {
    var container = $('#profile_login_form');
    if (elem) {
        container = $(elem).parents('form');
    }
    if (container) {
        let data = {
            partner: $(document).find('.partner').val(),
            h: $(document).find('.cart_token').val(),
        };
        data = Object.values(data).filter((e) => e).length > 0 ? data : {};

        $(container).find('.login_username').each(function () {
            data[$(this).attr('name')] = $(this).val();
        });

        const updateForms = (res) => {
            if (res.person_phone_block_button_html) {
                $(container).find('.phone_block_wrapper').find('.button_in_phone').html(res.person_phone_block_button_html).removeClass('nod');
            }
            const addedChar = res.person_phone_block_comment_html ?? '';
            $(container).find('.phone_block_wrapper').find('.added_charact').html(addedChar);
        }
        const locationPath = document.location.pathname;
        const currentLanding = locationPath.split('/').slice(0, 3).join('/');

        var ajax_obj = {
            url: `${currentLanding}/login/check/`,
            type: 'post',
            beforeSend: function () {
                $(container).find('.phone_block_wrapper').find('.added_charact').html('');
                $(container).find('.phone_block_wrapper').find('.button_in_phone').addClass('nod');
                $(container).find('.phone_block_wrapper').find('.button_in_phone').html('');
                $(container).find('.phone_block_wrapper').find('.login_loader').removeClass('nod');
            },
            data,
            dataType: 'json',
            complete: function (response) {
                if (response.responseText) {
                    $(container).find('.phone_block_wrapper').find('.login_loader').addClass('nod');
                    try {
                        res = $.parseJSON(response.responseText);
                        cache[JSON.stringify(data)] = res;
                    } catch (err) {
                        res = { result: 1, message: 'Данные отправлены' };
                    }
                    updateForms(res);
                }
            },
            cache: false,
            async: true,
        };
        const testCache = cache[JSON.stringify(data)];
        if (testCache !== undefined || !do_init) {
            ajax_obj.beforeSend();
            $(container).find('.phone_block_wrapper').find('.login_loader').addClass('nod');
            if (do_init) {
                updateForms(testCache);
            }
        } else {
            $.ajax(ajax_obj);
        }
    }
}

function send_prolong_as_sub(elem) {
    if (elem) {
        var error = 0;
        if (!$(elem).find('.prolong_sub_agreed').prop('checked')) {
            $(elem).find('.prolong_sub_agreed').parent().find('span').addClass('is-error');
            error = 1;
        }

        if (error == 0) {
            var service_id = $(elem).find('input[name="service_id"]').val();
            if ($('.popup-tarif-changed_' + service_id).is(':hidden')) {
                error = 1;
                $('.popup-tarif-changed_' + service_id).show();
            }
        }

        if (error == 0) {
            $('.popup-tarif-changed_' + service_id).hide();
            data = new Object;
            $(elem).find('.add_field').each(function () {
                data[$(this).attr('name')] = $(this).val();
            });
            const locationPath = document.location.pathname;
            const currentLanding = locationPath.split('/').slice(0, 3).join('/');
            var ajax_obj = {
                url: `${currentLanding}/profile/orders/send_prolong_as_sub/`,
                type: 'post',
                beforeSend: function () {
                    $(elem).find('.send_loader').removeClass('nod');
                    $(elem).find('.submit_button').addClass('nod');
                },
                data: data,
                dataType: 'json',
                complete: function (data) {
                    $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html('Заявка отправлена');
                    $(elem).find('.send_loader').addClass('nod');
                    //$(elem).find('.submit_button').removeClass('nod');

                    if (data.responseText) {
                        try {
                            res = $.parseJSON(data.responseText);
                        } catch (err) {
                            res = new Object();
                            res.result = 0;
                            res.message = 'Продление невозможно';
                        }

                        if (res.result == 0) {
                            if (res.message) {
                                $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html(res.message);
                            }
                        }
                        if (res.result == 1) {
                            $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html('Заявка отправлена');
                        }

                    }
                },
                cache: false,
                async: true,
            };

            $.ajax(ajax_obj);
        }
    }
    return false;
}


function send_prolong(elem) {
    if (elem) {
        data = new Object;
        $(elem).find('.add_field').each(function () {
            data[$(this).attr('name')] = $(this).val();
        });
        const locationPath = document.location.pathname;
        const currentLanding = locationPath.split('/').slice(0, 3).join('/');
        var ajax_obj = {
            url: `${currentLanding}/profile/orders/send_prolong/`,
            type: 'post',
            beforeSend: function () {
                $(elem).find('.send_loader').removeClass('nod');
                $(elem).find('.submit_button').addClass('nod');
            },
            data: data,
            dataType: 'json',
            complete: function (data) {
                $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html('Заявка отправлена');
                $(elem).find('.send_loader').addClass('nod');
                //$(elem).find('.submit_button').removeClass('nod');

                if (data.responseText) {

                    try {
                        res = $.parseJSON(data.responseText);
                    } catch (err) {
                        res = new Object();
                        res.result = 0;
                        res.message = 'Продление невозможно';
                    }
                    if (res.result == 0) {
                        if ($res.message) {
                            $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html($res.message);
                        }
                    }
                    if (res.result == 1) {
                        $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html('Заявка отправлена');
                    }

                }
            },
            cache: false,
            async: true,
        };

        $.ajax(ajax_obj);
    }
    return false;
}

function check_prolong(elem) {
    if (elem) {
        var day = $(elem).val();
        day = parseInt(day);
        if (day < 1 || !day) {
            $(elem).val('')
        } else {
            $(elem).val(day);
            var wrapper = $(elem).parents('.my_days_wrapper');
            var form = $(elem).parents('.profile_prolong_form');
            $(form).find('.day_count').val(day);

            var data = new Object;
            data.days = day;
            data.service_id = $(elem).parents('.profile_prolong_form').find('[name="service_id"]').val();

            const locationPath = document.location.pathname;
            const currentLanding = locationPath.split('/').slice(0, 3).join('/');
            var ajax_obj = {
                url: `${currentLanding}/profile/orders/check_prolong/`,
                type: 'post',
                beforeSend: function () {
                    $(wrapper).find('.myday').addClass('nod');
                    $(wrapper).find('.myday_all').addClass('nod');
                    $(wrapper).find('.loader_wrapper').removeClass('nod');
                },
                data: data,
                dataType: 'json',
                complete: function (data) {
                    $(wrapper).find('.myday').removeClass('nod');
                    $(wrapper).find('.myday_all').removeClass('nod');
                    $(wrapper).find('.loader_wrapper').addClass('nod');

                    if (data.responseText) {

                        try {
                            res = $.parseJSON(data.responseText);
                        } catch (err) {
                            res = new Object();
                            res.result = 0;
                            res.message = 'Продление невозможно';
                        }

                        if (res.myday) {
                            $(wrapper).find('.myday').html(res.myday);
                        }
                        if (res.myday_all) {
                            $(wrapper).find('.myday_all').html(res.myday_all);
                        }
                    }
                },
                cache: false,
                async: true,
            };

            $.ajax(ajax_obj);
        }

    }
}

function check_order_stop_form(elem) {
    if (elem) {
        var error = 0;
        var data = new Object;
        $(elem).find('.common_field_wrapper').find('.add_field').each(function () {
            data[$(this).attr('name')] = $(this).val();
        });
        data.new_address = $(elem).find('.new_address').val();
        var need_block = '.old_contact';
        if (data.new_address == 1) {
            need_block = '.new_contact';
        }
        $(elem).find(need_block).find('.add_field').each(function () {
            data[$(this).attr('name')] = $(this).val();
            if ($(this).hasClass('need_field') && !check_field($(this), 1)) {
                error = 1;
            }
        });

        if (error == 1) {
            go_to_error();
        }
        if (error == 0) {

            var ajax_obj = {
                url: $(elem).attr('action'),
                type: 'post',
                beforeSend: function () {
                    $(elem).find('.result_wrapper').addClass('nod');
                    $(elem).find('.result_wrapper').html('');
                    $(elem).find('.result_wrapper').removeClass('error-color');
                    $(elem).find('.result_wrapper').removeClass('green');
                    $(elem).find('.field_wrapper').addClass('nod');
                    $(elem).find('.loader_wrapper').removeClass('nod');
                },
                data: data,
                dataType: 'json',
                complete: function (data) {
                    $(elem).find('.loader_wrapper').addClass('nod');
                    $(elem).find('.result_wrapper').removeClass('nod');
                    //$(elem).find('.field_wrapper').removeClass('nod');


                    if (data.responseText) {

                        try {
                            res = $.parseJSON(data.responseText);
                        } catch (err) {

                            res = new Object();
                            res.result = 1;
                            res.message = 'Данные отправлены';
                        }

                        if (res.send.error) {
                            res.error = res.send.error;
                        }
                        if (res.error) {
                            res.result = 0;
                            console.log(res.error);
                            for (i = 0; i < res.error.length; i++) {
                                if (res.error[i].field != 'hidden') {
                                    $(elem).find('input[name=' + res.error[i].field + ']').addClass('is-error');
                                }
                            }
                            go_to_error();
                        }

                        if (res.result == 0) {
                            if (res.message) {
                                $(elem).find('.result_wrapper').removeClass('nod').addClass('green').html(res.message);
                            }
                        }
                        if (res.result == 1) {
                            $(elem).find('.close_button').click();
                            //$(elem).find('.result_wrapper').removeClass('nod').addClass('green').html('Заявка на возврат отправлена.Наши менеджеры свяжутся с Вами в ближайшее время');
                        }
                        if (res.send.error) {

                            $(elem).find('.result_message_button').find('textarea[name="message"]').text('Ошибка: ' + res.send.error);

                        }
                        $(elem).find('.result_message_button').click();
                        $(elem).find('.close_button').click();

                    }
                },
                cache: false,
                async: true,
            };

        }
        $.ajax(ajax_obj);

    }
    return false;
}

function check_default_address(elem) {
    if (elem) {
        if ($(elem).find('input[type="checkbox"]').prop('checked')) {
            $('.default_address').find('input[type="checkbox"]').prop('checked', false);
            $(elem).find('input[type="checkbox"]').prop('checked', true);
        }
    }
}

